var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "row" },
    [
      _c(
        "div",
        { staticClass: "col-lg-12 col-md-12 col-sm-12" },
        [
          _c(
            "c-table",
            {
              ref: "table",
              attrs: {
                title: "관련 비상조치계획서 목록",
                columns: _vm.grid.columns,
                gridHeight: _vm.grid.height,
                data: _vm.tabParam.emergencyList,
                filtering: false,
                columnSetting: false,
                usePaging: false,
                isExcelDown: false,
                hideBottom: true,
                editable: _vm.editable,
                rowKey: "sopEmergencyPlanId",
                selection: "multiple",
              },
              on: { linkClick: _vm.linkClick },
            },
            [
              _c(
                "template",
                { slot: "table-button" },
                [
                  _c(
                    "q-btn-group",
                    { attrs: { outline: "" } },
                    [
                      _vm.editable && !_vm.disabled
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "추가",
                              icon: "add",
                            },
                            on: { btnClicked: _vm.searchEmergency },
                          })
                        : _vm._e(),
                      _vm.editable &&
                      !_vm.disabled &&
                      _vm.tabParam.emergencyList.length > 0
                        ? _c("c-btn", {
                            attrs: {
                              showLoading: false,
                              label: "삭제",
                              icon: "remove",
                            },
                            on: { btnClicked: _vm.removeEmergency },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
      _c("c-dialog", { attrs: { param: _vm.popupOptions } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }